import React,{useEffect, useState} from 'react'
import Footer from './Footer'
import Header from './Header'
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import Img1 from "../Assets/images/img1.svg"
import Img2 from "../Assets/images/img2.svg"
import Img3 from "../Assets/images/img3.svg"
import Img4 from "../Assets/images/img4.svg"
import Img5 from "../Assets/images/img5.svg"
import Img6 from "../Assets/images/img6.svg"
import Img7 from "../Assets/images/img7.svg"
import Img8 from "../Assets/images/img8.svg"
import Img9 from "../Assets/images/img9.svg"
import Img10 from "../Assets/images/img10.svg"
import Img11 from "../Assets/images/img11.svg"
import Img12 from "../Assets/images/img12.svg"
import Img13 from "../Assets/images/img13.svg"
import Img14 from "../Assets/images/img14.svg"
import Img15 from "../Assets/images/img15.svg"
import Img16 from "../Assets/images/img16.svg"
import Img17 from "../Assets/images/img17.svg"
import Img18 from "../Assets/images/img18.svg"
import Img19 from "../Assets/images/img19.svg"
import Img20 from "../Assets/images/img20.svg"
import Img21 from "../Assets/images/img21.svg"
import banner from "../Assets/images/banner.png"
import whylabel from "../Assets/images/whylabel.png"
import files from "../Assets/images/files.png"
import trading from "../Assets/images/trading.png"
import { getAboutData, getFeatureData, getFeaturedesData, getLaunchpadData, getRoadmapdesData, getSettingsData } from '../Hooks/UseBackend';
import { backurl } from '../Config/env';
import { UsegetsingleSaleInfo } from '../Hooks/useContract';

const Land = () =>{
  const [aboutdata,setaboutdata] = useState([])
  const [featuredata,setfeaturedata] = useState([])
  const [featuredesdata,setfeaturedesdata] = useState([])
  
  const [roadmapdata,setroadmapdata] = useState([])
  const [totalLaunchPadlength,settotalLaunchPadlength] = useState([])
  const [singledata1,setsingledata1] = useState({})
  const [singledata2,setsingledata2] = useState({})
  const [singledata3,setsingledata3] = useState({})
 const  responsive1 = {
    0: {
        items: 1,
    },
    424:{
        items: 1,
    },
    576:{
        items: 2,
    },
    768: {
        items: 3,
    },
    1200: {
        items: 4,
    },
}
useEffect(() =>{
  getData();

},[])


const getData =async() =>{
       
  var data = await getAboutData();
  setaboutdata(data)
  var feature = await getFeatureData();
  setfeaturedata(feature);
  var featruedes = await getFeaturedesData();
  setfeaturedesdata(featruedes)
  var roadmapdes = await getRoadmapdesData();
  setroadmapdata(roadmapdes)
  var data = await getLaunchpadData();
  
   settotalLaunchPadlength(data)
   var singledata = await UsegetsingleSaleInfo(data[0]?.presaleAddress);
   setsingledata1(singledata);
   var singledata2 = await UsegetsingleSaleInfo(data[1]?.presaleAddress);
   
   setsingledata2(singledata2);
   var singledata3 = await UsegetsingleSaleInfo(data[2]?.presaleAddress);
   setsingledata3(singledata3);
   
  
}
return(
   <div className='land'>
    <Header />
    <div className='container container-theme'>
        <section className='banner'>
            <div className='row align-items-center'>
              <div className='col-md-6'>
                  <div className='ban-cont'>
                    <p className='themeclr'>{aboutdata && aboutdata[0]?.Title}</p>
                    <h1 className='heading'>{aboutdata && aboutdata[0]?.Heading}</h1>
                    <p>{aboutdata && aboutdata[0]?.Description}</p>
                   <a target='blank' href="https://labelm.io/launchpad">    <button className='btn getstarted'>Get Started</button></a>
                  </div>
              </div>
              <div className='col-md-6 d-none d-md-block'>
                 <div className='bannerimg text-center'>
                 <img src={backurl + `/Images/${aboutdata && aboutdata[0]?.logoURI}`} />
                  </div>
              </div>
            </div>
        </section>
        <section className='marketcapital bgtheme br-14 p-4 text-dark'>
          {/* <h3 className='bold'>Market Cappitalization</h3>
          <p>$902,214,730</p> */}
          <h3 className='bold'>OP1 Information</h3>
           <div className='row'>
              <div className='col-md-4 col-sm-6 mb-4'>
                <div className='infos'>
                  <p className="mb-0">Sell 1 (90days Locked  staking)</p>
                  <p className="mb-0">Price:  1 USDT = {singledata1 && singledata1?.pricePerCrypto} LBM</p>
                  <p className="mb-0">Reward: 0.05%</p>
                  <p className="mb-0">Sale : {singledata1 && singledata1.ispresaleopen ? "On Process" : "Stopped"}</p>
                  {/* <a target='blank' href="https://labelm.io/launchpad"> <button className='btn getstarted dark mt-3'>Get Started</button></a> */}

                </div>
              </div>
              <div className='col-md-4 col-sm-6 mb-4'>
                <div className='infos'>
                  <p className="mb-0">Sell 2 (180days Locked  staking)</p>
                  <p className="mb-0">Price: 1 USDT = {singledata2 && singledata2.pricePerCrypto} LBM</p>
                  <p className="mb-0">Reward: 2%</p>
                  <p className="mb-0">Sale: {singledata2 && singledata2.ispresaleopen ? "On Process" : "Stopped"}</p>
                  {/* <a target='blank' href="https://labelm.io/launchpad"> <button className='btn getstarted dark mt-3'>Get Started</button></a> */}

                </div>
              </div>
              <div className='col-md-4 mb-4'>
                <div className='infos'>
                  <p className="mb-0">Sell 3 (365days Locked  staking)</p>
                  <p className="mb-0">Price: 1 USDT = {singledata3 && singledata3?.pricePerCrypto} LBM</p>
                  <p className="mb-0">Reward: 15%</p>
                  <p className="mb-0">Sale: {singledata3 && singledata3.ispresaleopen ? "On Process" : "Stopped"}</p>
                 
                 
                  {/* <a target='blank' href="https://labelm.io/launchpad"> <button className='btn getstarted dark mt-3'>Get Started</button></a> */}

                </div>
              </div>
           </div>
        </section>
        <section className='banner'>
            <div className='row align-items-center'>
              <div className='col-md-6 d-none d-md-block'>
                 <div className='bannerimg text-center'>
                 <img src={backurl + `/Images/${aboutdata && aboutdata[1]?.logoURI}`} />
                  </div>
              </div>
              <div className='col-md-6'>
                  <div className='ban-cont'>
                    <p className='themeclr'>{aboutdata && aboutdata[1]?.Title}</p>
                    <h1 className='innerheading'>{aboutdata && aboutdata[1]?.Heading}</h1>
                    <p>{aboutdata && aboutdata[1]?.Description}</p>
                    {/* <a target='blank' href="https://labelm.io/launchpad"><button className='btn getstarted'>Get Started</button></a> */}
                  </div>
              </div>
            </div>
        </section>
        {/* <section className='logos_sec'>
          <div className='logogrid'>
            <img src={Img1} />
            <img src={Img2} />
            <img src={Img3} />
            <img src={Img4} />
            <img src={Img5} />
            <img src={Img6} />
            <img src={Img7} />
            <img src={Img8} />
            <img src={Img9} />
            <img src={Img10} />
            <img src={Img11} />
            <img src={Img12} />
            <img src={Img13} />
            <img src={Img14} />
            <img src={Img15} />
            <img src={Img16} />
            <img src={Img17} />
            <img src={Img18} />
            <img src={Img19} />
            <img src={Img20} />
            <img src={Img21} />
          
          </div>
        </section> */}
        <section className='feature'>
          <div className='text-center'>
                <p className='themeclr'>{featuredata && featuredata[0]?.Title}</p>
                <h1 className='innerheading'>{featuredata && featuredata[0]?.Heading}</h1>
                <p className='smallp'>{featuredata && featuredata[0]?.Description} </p>
                    
          </div>
           <div className='row mt-5'>
            <div className='col-md-4'>
                 <div className='features-list text-center p-4'>
                 <img  className="mb-3"src={backurl + `/Images/${featuredesdata && featuredesdata[0]?.logoURI}`} />
                    {/* <img src={files} /> */}
                    <h4>{featuredesdata && featuredesdata[0]?.Heading}</h4>
                    <p>{featuredesdata && featuredesdata[0]?.Description} </p>
                 </div>
            </div>
            <div className='col-md-4'>
            <div className='features-list text-center p-4'>
                 <img  className="mb-3"src={backurl + `/Images/${featuredesdata && featuredesdata[1]?.logoURI}`} />
                    {/* <img src={files} /> */}
                    <h4>{featuredesdata && featuredesdata[1]?.Heading}</h4>
                    <p>{featuredesdata && featuredesdata[1]?.Description} </p>
                 </div>
            </div>
            <div className='col-md-4'>
            <div className='features-list text-center p-4'>
                 <img  className="mb-3"src={backurl + `/Images/${featuredesdata && featuredesdata[2]?.logoURI}`} />
                    {/* <img src={files} /> */}
                    <h4>{featuredesdata && featuredesdata[2]?.Heading}</h4>
                    <p>{featuredesdata && featuredesdata[2]?.Description} </p>
                 </div>
            </div>
           </div>
        </section>
        <section className='banner trading'>
            <div className='row align-items-center'>
              <div className='col-md-6'>
                  <div className='ban-cont'>
                    <p className='themeclr'>{aboutdata && aboutdata[2]?.Title}</p>
                    <h1 className='innerheading'>{aboutdata && aboutdata[2]?.Heading}</h1>
                    <p>{aboutdata && aboutdata[2]?.Description}</p>
                    {/* <a target='blank' href="https://labelm.io/launchpad"><button className='btn getstarted'>Get Started</button></a> */}
                  </div>
              </div>
              <div className='col-md-6 d-none d-md-block'>
                 <div className='bannerimg text-center'>
                 <img src={backurl + `/Images/${aboutdata && aboutdata[2]?.logoURI}`} />
                  </div>
              </div>
            </div>
        </section>
        <section className=" themesec roadmap resp991 pt-md-5" id="roadmap">
    <div  className="">
         <div className="container container_custom">
         <div className='ban-cont text-center'>
                    <p className='themeclr'>{featuredata && featuredata[1]?.Title}</p>
                    <h1 className='innerheading'>{featuredata && featuredata[1]?.Heading}</h1>
                    <p className='smallp'>{featuredata && featuredata[1]?.Description}</p>
                  </div>
          
                 <div className="slider_sec_1 fullinerelative">
                     <div className='fullline'></div>
                     <OwlCarousel items={3}  
                        className="owl-theme"  
                        loop={false} 
                        nav={false} 
                        margin={0} autoplay ={false} responsive={responsive1} dots={false}>  
                        {roadmapdata && roadmapdata.map((item) =>
  <div className='car-item completed'>
  <div>
       
       <div className='line'></div>
       <h6 className=' roadmapbtn'>{item?.Heading}</h6>
       <p>{item?.Description}</p>
       
  </div>
</div>
                        )}
                       
                        {/* <div className='car-item inprogress'>
                           <div>
                                
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Feb-Apr 2022</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                
                           </div>
                        </div>  
                        <div className='car-item notyetstart'>
                           <div>
                               
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Jan-Apr 2022</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                
                           </div>
                        </div> 
                        <div className='car-item notyetstart'>
                           <div>
                               
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Jan-Apr 2022</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                
                           </div>
                        </div>
                        <div className='car-item notyetstart'>
                           <div>
                               
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Jan-Apr 2022</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                           </div>
                        </div>
                        <div className='car-item notyetstart'>
                           <div>
                                
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Jan-Apr 2022</h6>
                                <p>Lemuria project expansion with Real Estate, Explorable, Wearable, Tradeable & Learning</p>
                           </div>
                        </div>  */}
                        
                    </OwlCarousel> 
                </div> 
             
        </div>
    </div>
</section>
    </div>
    <Footer />
    </div>
  )
}

export default Land