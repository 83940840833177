export const  style = {
      
                        
    minWidth: '300px',
    minHeight: '55px',
    border: '5px solid #F6DF2D',
    padding: '16px',
    color: '#000'
    
}

export const iconTheme = {
    primary: '#F6DF2D',
    secondary: '#000',
}

export const  position= {
    position: "top-center"
}