export const ONEDAYINSECONDS = 86400;
export const frontendurl  =     window.location.origin;

// export const backurl  = "http://localhost:2053" // local

// export const backurl   =    "http://43.204.99.101:7011" // testnet

 export const backurl = "https://api.labelm.io"// mainnet

export const BURN = "0x000000000000000000000000000000000000dEaD";

export const BLOCKS_PER_YEAR = 1051200;

export const CHAINS = [

    // {
    //     NAME: "BSC-TEST",
    //     RPC_URL:"https://data-seed-prebsc-1-s1.binance.org:8545/", // BSC TESTNET
    //     CHAIN_ID:97,
    //    
    //     TOKENADDRESS: "0x5d92503f8B902EDaEcA16312963b1A04d6AEFf01", // 
    //     USDTADDRESS : "0xDFCd0b91AE2BB34a90D9b4970Dc113DFaf25004d",
    //     MASTERCHEF: "0xcA491033CDCdE5407F5c7fCFC84209312d0AdBa4", //  BSC NEW TESTNET
    //     LOCKEDSTAKING : "0x5872cCdD3ea66bAB0DC928f20762Bc9B3d89B007"

        
    // },

  //   {
  //     NAME: "AVALANCHE-TEST",
  //     RPC_URL:" https://api.avax-test.network/ext/bc/C/rpc", // BSC TESTNET
  //     CHAIN_ID:43113,
     
  //     TOKENADDRESS: "0xd7613f4f96d039f9f15b75d51232719d220b047e", // 
  //     USDTADDRESS : "0x8d9f46183A23d81dcCc172C92bDb4553c1b67FFf",
  //     MASTERCHEF: "0x6A8BCf0517522C15Ee9E9f5fbFf8a5451fCa4612", //  BSC NEW TESTNET
  //     LOCKEDSTAKING : "0x6Cb0b0CD91f80d4799E3Ae1C3D9d7F78bC037ABe"

      
  // },
  //   {
  //     NAME: "BSC-TEST",
  //     RPC_URL:"https://api.avax-test.network/ext/bc/C/rpc", // BSC TESTNET
  //     CHAIN_ID:43113,
  //     ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
  //     TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // 
  //     MASTERCHEF: "0x5d012e3a0Aa2abfF0862a72FB5F876028FE751B3", //  BSC NEW TESTNET

      
  // },
  //   {
  //     NAME: "Polygon-TEST",
  //     RPC_URL:"https://matic-mumbai.chainstacklabs.com",
  //     CHAIN_ID:80001, 
  //     TOKENADDRESS: "0x9433f3BaAE2984C6F5C8DE6989aB6dee23eaEE17",
  //      MASTERCHEF: "0x74962D8c90dCb1e4761DF94115BE449044179e5A", // new 
  //     // MASTERCHEF: "0x1afd408a544e901af55cB8735d8c6B49c47A11b2",//old
  //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
  {
    NAME: "BSC-MAINNET",
    RPC_URL:"https://bsc-dataseed1.binance.org/",
    CHAIN_ID:56,
    TOKENADDRESS: "0xBb8ebAddbdd88995485fdB224C3C956555034bd7", //LBM
    USDTADDRESS :"0x55d398326f99059fF775485246999027B3197955", // USDTADDRESS
    NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B",
    MASTERCHEF: "0x43a16a065b63E6Bdc90d7FFaFe7461d823C70194", // launchpad proxy
    LOCKEDSTAKING : "0x19F443a185CB64dD4aE58D21E2E4b47Fe606B38D" // locked staking
}

];

