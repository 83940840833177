import React , {useEffect, useState} from 'react'
import { getSettingsData } from '../Hooks/UseBackend';

const Footer = () => {
    const [settingdata,setsettingdata] = useState([])
    useEffect(() =>{
        getData()
    })

    const getData =async() =>{
        var settingdata = await getSettingsData();
  setsettingdata(settingdata);
    }
    return(
        <div className='footer'>
            <div className='container container-theme'>
                <div className='row'>
                    
                    {/* <div className='col-sm-4'>
                        <ul className='text-sm-start ps-0'>
                           <li>
                                <a target="blank" href={settingdata && settingdata[0]?.Description}>
                                    <span className='fa fa-facebook-square'></span>
                                </a>
                            </li>
                            <li>
                                <a target="blank"href={settingdata && settingdata[1]?.Description}>
                                    <span className='fa fa-twitter-square'></span>
                                </a>
                            </li>
                            <li>
                                <a target="blank"href={settingdata && settingdata[2]?.Description}>
                                    <span className='fa fa-instagram'></span>
                                </a>
                            </li>
                        </ul>
                    </div> */}
                    <div className='col-sm-8 '>
                        <p className='text-sm-end textclr'>COPYRIGHT @ 2022 .ALL RIGHTS RESERVED</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer